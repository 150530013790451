import consumer from "./consumer"

const loggerTable = document.querySelector("#logger-table");

if(loggerTable) {
  consumer.subscriptions.create({
    channel: "RequestLogUpdatesChannel",
    id: loggerTable.dataset.loggerId
  }, {
    connected() {
      const urlParams = new URLSearchParams(window.location.search);
      this.live = urlParams.get('live') === 'true';
    },

    disconnected() {
      if(this.live) {
        alert('Live updates disconnected. Please refresh the page to reconnect.');
      }
    },

    received(data) {
      if(this.live) {
        document.querySelector(data.selector).insertAdjacentHTML(data.position, data.html);

        highlightPreCode();
      }
    }
  });
}
